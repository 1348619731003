/**
 * External Dependencies
 */
import 'jquery';
import 'bootstrap';

import jQueryBridget from 'jquery-bridget';
import Panzoom from '@panzoom/panzoom';
import Flickity from 'flickity';
import Isotope from 'isotope-layout';
import scrollama from 'scrollama';
import throttle from 'lodash/throttle';
import anime from 'animejs';

// import 'flickity-fade';

Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);
jQueryBridget('isotope', Isotope, $);

Flickity.prototype._createResizeClass = function() {
  setTimeout(() => {
    $(this.element).addClass('flickity-resize');
  });
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

$(document).ready(() => {
  const aarowShape = "M72.5,8V92a6.52,6.52,0,0,1-11.13,4.62l-42-42a6.51,6.51,0,0,1,0-9.22l42-42A6.52,6.52,0,0,1,72.5,8Z";

  // Set cookie to hide alert when dismissing/acknowledging
  $('#lwAlertContainer .alert').on('closed.bs.alert', function () {
    var expdate = new Date();
    // Set expiry for 1 month
    expdate.setHours(expdate.getHours() + (30 * 24));

    var cookie = [
      'lwPopupDismissed=true',
      'expires=' + expdate.toUTCString(),
      'path=/',
    ];

    document.cookie = cookie.join(';');
  });

  // Mobile menu buttons
  $('.btn-menu').on('click', function () {
    // Toggle class to menu container to reveal menu
    $('body').toggleClass('menu-active');
  });

  if ( $('[data-play]').length ) {
    const playsInline = function () {
      // Default to showing videos
      let inlineVideo = true;

      // Do some conditional checking to see when we shouldn't show videos because they won't play inline
      if (
        navigator.userAgent.match(/CriOS/i) &&
        'playsInline' in document.createElement('video')
      ) {
        // Chrome on iOS supports this now
        inlineVideo = true;
      } else if (navigator.userAgent.match(/CriOS/i)) {
        // But not older versions
        inlineVideo = false;
      } else if (
        navigator.userAgent.match(
          /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)|; wv)/i
        )
      ) {
        // iOS or Android webviews
        inlineVideo = false;
      } else if (
        navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/i) &&
        'playsInline' in document.createElement('video')
      ) {
        // This should be iOS 10 devces
        inlineVideo = true;
      } else if (navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/i)) {
        // This should be iOS devices less than iOS 10
        inlineVideo = false;
      }

      return inlineVideo;
    };

    // Video Handling
    $('[data-play]').each(function () {
      const $video = $(this);

      const videoElement = $video.get(0);
      const fade = $video.data('fade');
      const notMobileFlag = $video.data('mobile');
      const howPlay = $video.data('play');

      const videoOnMobile = ($(window).innerWidth() < 768) && notMobileFlag;

      let isPlaying = false;

      const doPause = function (videoElement, callback) {
        if (isPlaying) {
          let pausePromise = videoElement.pause();

          if (pausePromise) {
            pausePromise.then(function () {
              isPlaying = false;
              if (typeof callback === 'function') {
                callback();
              }
            });
          } else {
            isPlaying = false;
            if (typeof callback === 'function') {
              callback();
            }
          }
        }
      }

      const doPlay = function (videoElement) {
        if (!isPlaying) {
          let playPromise = videoElement.play();

          if (playPromise) {
            playPromise.then(function () {
              isPlaying = true;
            });
          } else {
            isPlaying = true;
          }
        }
      }

      if (playsInline() && !videoOnMobile) {
        // Videos are invisible by default so there's not a flash on mobile
        $video.removeClass('invisible');

        // Handle 404s or other errors by removing the video and fading to poster image
        $video.find('source').last().on('error', function () {
          doPause(videoElement, function () {
            $video.remove();
            $('.media-wrapper').addClass('fade-to-image');
          });
        });

        // If "fade" is set, then fade out to the featued image when the video is over
        if (fade) {
          $video.on('timeupdate', function (e) {
            if (
              e.target.duration - 0.3 >= 0 &&
              e.target.duration - e.target.currentTime <= 0.3
            ) {
              $('.media-wrapper').addClass('fade-to-image');
              $(e.target).addClass('finished-playing');
            }
          });
        }

        if (howPlay === 'hover') {
          // Play Video on hover
          $video.on('hover',
            function () {
              doPlay(videoElement);
            },
            function () {
              doPause(videoElement);
            }
          );
        } else if (howPlay === 'inview') {
          // Play video when in view
          const videoScroller = scrollama();

          $(window).on('resize', throttle(videoScroller.resize, 500));

          videoScroller
            .setup({
              step: this,
              offset: ($video.offset().top + 1) + 'px',
              // debug: true,
            })
            .onStepEnter(function () {
              if (!$video.hasClass('finished-playing')) {
                doPlay(videoElement);
              }
            })
            .onStepExit(function () {
              if (!$video.hasClass('finished-playing')) {
                doPause(videoElement);
              }
            });
        } else {
          // Otherwise just play
          doPlay(videoElement);
        }
      } else {
        // If we can't play videos inline, just remove them
        doPause(videoElement, function () {
          $video.remove();
          $('.media-wrapper').addClass('fade-to-image');
        });
      }
    });
  }

  if ( document.getElementById('panZoomMap') ) {
    const elem = document.getElementById('panZoomMap');
    const panzoom = Panzoom(elem, {
      maxScale: 5,
      contain: 'outside',
      canvas: true,
      touchAction: '',
    });

    $('#mapZoomIn').on('click', panzoom.zoomIn)
    $('#mapZoomOut').on('click', panzoom.zoomOut)

    var wildlifeRequest = null;
    var $wildlifeMapButtons = $('.wildlife-map-buttons');

    var $wildlifeMapControls = $('.wildlife-map-controls');

    $wildlifeMapControls.flickity({
      cellSelector: '.wildlife-map-buttons',
      contain: true,
      pageDots: true,
      arrowShape: aarowShape,
      draggable: false,
      // adaptiveHeight: true,
      cellAlign: 'left',
    });

    $wildlifeMapButtons.on('click', '.wildlife-map-button', function() {
      var $button = $(this);

      if ( wildlifeRequest ) {
        wildlifeRequest.abort();
      }

      // Make the AJAX request
      wildlifeRequest = $.post(window.lillooetWild.adminAjax, {
        action: 'wildlife_details',
        wildlifeId: $button.data('id'),
      })
      .done(function(response) {
        if (typeof response.data === 'object') {
        // if (response.data !== undefined) {
          // Something went right
          var $prevMap = $('.wildlife-map-stack').find('.wildlife-map-stack-active');

          $('.wildlife-summary-link').attr('href', response.data.url);
          $('.wildlife-summary-title').html(response.data.summary);
          $('.wildlife-summary-meta').html(response.data.scientific_name);

          $wildlifeMapButtons.find('.active').removeClass('active');
          $button.addClass('active');

          if (response.data.map) {
            if ( $prevMap.length ) {
              $prevMap.fadeOut(200, function() {
                $(this).remove();
                $(response.data.map).hide().prependTo('.wildlife-map-stack').fadeIn(200);
              });
            } else {
              $(response.data.map).hide().prependTo('.wildlife-map-stack').fadeIn(200);
            }
          } else {
            $prevMap.fadeOut(200, function() {
              $(this).remove();
            });
          }
        }
      })
      .fail(function() {
        // Something went wrong
      });
    });
  }

  var shuffleRequest = null;

  function get_wildlife_exclude() {
    var $excludeObjs = $('.wildlife-shuffle-item.stack-bottom');
    var excludeIDs = null;

    if ( $excludeObjs.length ) {
      excludeIDs = $excludeObjs.map(function() {
        return $(this).data('id');
      }).get()
    }

    return excludeIDs;
  }

  $('.btn-shuffle').on('click', function() {
    if ( shuffleRequest ) {
      shuffleRequest.abort();
    }

    // Make the AJAX request
    shuffleRequest = $.post(window.lillooetWild.adminAjax, {
      action: 'wildlife_shuffle',
      wildlifeExclude: get_wildlife_exclude(),
    })
    .done(function(response) {
      // console.log(response);
      if (typeof response.data === 'object') {
        if (response.data.wildlife) {
          // var itemStacks = document.querySelectorAll('.wildlife-grid-item-stack');
          var $itemStacks = $('.wildlife-shuffle-item-stack');

          var elements = document.querySelectorAll('.wildlife-shuffle-item.stack-top');

          anime({
            targets: elements,
            opacity: 0,
            duration: 300,
            easing: 'easeInQuad',
          });

          anime({
            targets: elements,
            translateX: 250,
            rotate: function() {
              return anime.random(0, 45);
            },
            duration: 400,
            // easing: 'easeOutCirc',
            delay: anime.stagger(100),
            begin: function() {
              $('#wildlifeShuffle').innerHeight($('#wildlifeShuffle .wildlife-shuffle').height());

              $itemStacks.each(function() {
                var nextStackItem = $(this).find('.stack-bottom');

                nextStackItem.addClass('stack-top').removeClass('stack-bottom');
              });

              Object.entries(response.data.wildlife).forEach((entry, index) => {
                const value = entry[1];
                // itemStacks[index].insertAdjacentHTML(value);
                $itemStacks.eq(index).append(value);
              });
            },
            complete: function() {
              elements.forEach(e => e.remove());
              $('#wildlifeShuffle').innerHeight($('#wildlifeShuffle .wildlife-shuffle').height());
            }
          });

          // $('#wildlifeShuffle .wildlife-shuffle-item.stack-bottom').
        }
      }
    })
    .fail(function() {
      // Something went wrong
    });
  });

  /**
   * Isotope filtering for Wildlife grid
   */
  if ( $('#wildlifeGridView').length ) {
    /**
     * Isotope Pagination
     *
     * reference: https://codepen.io/TimRizzo/details/ervrRq
     */

    // Defaults
    var filterValue = '*';
    var itemSelector = '.wildlife-grid-item';
    var itemsPerPage = 6;
    var currentPage = '.page-1';

    var filterSelector = '.wildlife-grid-filters';
    var $filters = $(filterSelector);
    var $isotopePager = $('<div class="wildlife-grid-pager"></div>');
    var $pagerPrevNext = $('<div class="wildlife-grid-prev-next"><button class="btn btn-lg btn-prev-next" data-page="prev"><i class="fa-icon fas fa-caret-left"></i> Prev</button><button class="btn btn-lg btn-prev-next" data-page="next">Next <i class="fa-icon fas fa-caret-right"></i></button></div>');

    var $container = $('.wildlife-grid').isotope({
      itemSelector: itemSelector,
      layoutMode: 'fitRows',
    });

    function changeFilter(selector) {
      if ( !selector.includes('page') ) {
        // This is a silly hack
        selector = currentPage;
      }

      // console.log('----');
      // console.log('currentPage: '+ currentPage);
      // console.log('selector: ' + selector);

      $container.isotope({ filter: selector });
    }

    var createPager = function(filterValue) {
      var itemsLength = $container.children(filterValue).length;
      var pages = Math.ceil(itemsLength / itemsPerPage);
      var pageClass = '';
      var pagerFilterValue = '';
      currentPage = filterValue+'.page-1';

      // Clear out pager contents
      $isotopePager.html('');

      if ( pages > 1 ) {
        for( var i = 0; i < pages; i++ ) {
          var pagerClass = 'btn btn-sm btn-filter pager';
          pageClass = '.page-' + (i+1);
          pagerFilterValue = filterValue === '*' ? pageClass : filterValue + pageClass;

          if ( i === 0 && !filterValue.includes('page') ) {
            currentPage = pagerFilterValue;
            pagerClass += ' active';
          }

          $isotopePager.append($('<button class="'+pagerClass+'" data-filter="'+pagerFilterValue+'">' + (i+1) + '</button>'));
        }

        $isotopePager.append($pagerPrevNext);

        $filters.append($isotopePager);
      }
    }

    function setPagesOnItems(filterValue) {
      var item = 1;
      var page = 1;

      $container.children(filterValue).each(function() {
        // increment page if a new one is needed
        if ( item > itemsPerPage ) {
          page++;
          item = 1; // reset item variable
        }

        // add page number to element as a class
        var pageString = 'page-' + page.toString();

        // Strip previous/update page classes
        $(this).attr('class', function(i, c){
          return c.replace(/(^|\s)page-\S+/g, '');
        }).addClass(pageString);

        item++;
      });
    }

    function setPagination(filterValue) {
      setPagesOnItems(filterValue);
      createPager(filterValue);
    }

    $filters.on('click', 'button.btn-filter', function() {
      filterValue = $(this).attr('data-filter');

      $(this).addClass('active').siblings().removeClass('active');

      // Set page values on items and build pager UI
      if ( !filterValue.includes('page') ) {
        setPagination(filterValue)
      }

      changeFilter(filterValue);
    });

    // Pager Prev/Next Navigation
    $filters.on('click', '.btn-prev-next', function() {
      var prevNextValue = $(this).attr('data-page');
      var $activePager = $isotopePager.find('.active');

      // console.log($activePager);

      if ( prevNextValue === 'next' ) {
        if ( $activePager.next() ) {
          $activePager.next().trigger('click');
        }
      } else {
        if ( $activePager.prev() ) {
          $activePager.prev().trigger('click');
        }
      }
    });

    setPagination(filterValue);
    changeFilter(filterValue);
  }

  var $galleryCarousel = $('.wildlife-gallery-carousel');

  $('#wildlifeLightbox').on('shown.bs.modal', function (e) {
    var $button = $(e.relatedTarget);
    var cell = $button.data('cell');

    if ( $galleryCarousel.data('flickity') === undefined ) {
      // show
      $galleryCarousel.removeClass('d-none');
      // trigger redraw for transition
      $galleryCarousel[0].offsetHeight;

      $galleryCarousel.flickity({
        cellAlign: 'center',
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        arrowShape: aarowShape,
        lazyLoad: 2,
        initialIndex: cell,
      });
    } else {
      $galleryCarousel.flickity( 'select', cell);
    }
  });

  var $wildlifeViewsCarousel = $('.wildlife-views');

  $wildlifeViewsCarousel.flickity({
    cellAlign: 'center',
    prevNextButtons: false,
    pageDots: false,
    draggable: false,
    adaptiveHeight: true,
    arrowShape: aarowShape,
    // fade: true,
  });

  $('.btn-view-index').on( 'click', function() {
    $wildlifeViewsCarousel.flickity( 'select', 0);
  });

  // next
  $('.btn-view-map').on( 'click', function() {
    $wildlifeViewsCarousel.flickity( 'select', 1);
  });


  $('.contributors-carousel').flickity({
    cellSelector: '.contributors-carousel-item',
    // wrapAround: true,
    contain: true,
    pageDots: false,
    arrowShape: aarowShape,
    // adaptiveHeight: true,
    cellAlign: 'center',
  });
});
